import React, { useContext, useEffect } from 'react';
import { Form, useFormikContext, withFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { InputBox, Enum } from '../components';
import {
  EMAIL_ERROR_MSG,
  TELEPHONE_MATCHER,
  TELEPHONE_ERROR_MESSAGE,
  FULL_NAME_ERROR_MESSAGE,
} from '../validation-utils';
import { OneOffAuthContext } from '../../../components/oneOffAuth/OneOffAuthContext';
import { ErrorList } from '../components/ErrorList';

const questionTitles = {
  provider: {
    person_fullname: 'Full name',
    email: 'Email address',
    telephone: 'Phone number',
    contact_method: 'What is your preferred way to be contacted?',
  },
};

const ProviderSchema = Yup.object().shape({
  provider: Yup.object().shape({
    person_fullname: Yup.string().required(FULL_NAME_ERROR_MESSAGE),
    email: Yup.string().email(EMAIL_ERROR_MSG).required(EMAIL_ERROR_MSG),
    telephone: Yup.string().matches(TELEPHONE_MATCHER, TELEPHONE_ERROR_MESSAGE),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: ProviderSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About You',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'reporter-type';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('provider-information');
    formikBag.props.updateValues(values, next);
  },
});

const ProviderInformation = ({ setFieldValue, values }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  formik.values.matomo = { trackEvent };
  const { getEmail } = useContext(OneOffAuthContext);
  const email = getEmail();
  if (email) {
    formik.values.provider.email = email;
  }

  useEffect(() => {
    trackPageView({
      documentTitle: 'About You - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'About You',
      action: 'Started',
    });
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`provider-information`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <h1 className="govuk-heading-l" data-test="one-off-sar-heading">
          About you
        </h1>
        <p className="govuk-body">
          These details are required so that police can contact you should they
          need more information about your report
        </p>
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList questionTitles={questionTitles} />
            <Form autoComplete="on">
              <div className="govuk-grid-column">
                <InputBox
                  question={questionTitles.provider.person_fullname}
                  name="provider.person_fullname"
                  subText="Enter your first and last names"
                  hintId="person_fullname"
                />
                <InputBox
                  question={questionTitles.provider.email}
                  name="provider.email"
                  subText="An email address you have access to"
                  hintId="email"
                />
                <InputBox
                  question={questionTitles.provider.telephone}
                  isNumber={true}
                  name="provider.telephone"
                  size="20"
                  subText="For international numbers, include the country code. For example, +353."
                  hintId="telephone"
                />
              </div>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[
                    { value: 'Email' },
                    { value: 'Phone' },
                    { value: `I don't mind` },
                  ]}
                  name="provider.contact_method"
                  direction="col"
                  values={values}
                  data-test="provider-contact_method"
                  questionLarge={questionTitles.provider.contact_method}
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ProviderInformationWithFormik = formikWrapper(ProviderInformation);

export { ProviderInformationWithFormik as ProviderInformation };
