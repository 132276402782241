import React, { useContext } from 'react';
import { AuthContext } from '../../components/auth/AuthContext';

export const RegularReporting = () => {
  const { handleLogin } = useContext(AuthContext);

  const login = (e) => {
    e.preventDefault();
    handleLogin('/home');
  };

  return (
    <>
      <h1 className="govuk-heading-l" data-test="regular-reporting-header">
        Sign in to your account
      </h1>
      <p className="govuk-body">
        <a
          href="#"
          className="govuk-link"
          onClick={login}
          data-test="regular-reporting-link"
        >
        Sign in to your account
        {' '}
        </a>
       if you have agreed to provide data regularly to the Home Office.
      </p>
      <br />
      <br />
      <br />
    </>
  );
};
