import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { TextArea, InputBox, Date } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  getDobValidationSchema,
  EMAIL_ERROR_MSG,
  TELEPHONE_ERROR_MESSAGE,
  TELEPHONE_MATCHER,
} from '../validation-utils';
import { useHistory } from 'react-router-dom';
import { ErrorList } from '../components/ErrorList';

const questionTitles = {
  about_person: {
    buyer: {
      email: 'Email address',
      telephone: 'Phone number',
      dob: 'Date of birth',
    },
  },
};

const BuyerInformationSchema = Yup.object().shape({
  about_person: Yup.object().shape({
    buyer: Yup.object().shape({
      email: Yup.string().email(EMAIL_ERROR_MSG),
      telephone: Yup.string().matches(
        TELEPHONE_MATCHER,
        TELEPHONE_ERROR_MESSAGE
      ),
      ...getDobValidationSchema(),
    }),
  }),
});

const formikWrapper = withFormik({
  validationSchema: BuyerInformationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Buyer Information',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'payment-method';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('buyer-information');
    formikBag.props.updateValues(values, next);
  },
});

const BuyerInformation = ({ setFieldValue, values }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  formik.values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Buyer Information - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Buyer Information',
      action: 'Started',
    });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`buyer-information`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">
              Details about the suspicious person
            </h1>
            <ErrorList questionTitles={questionTitles} />
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <span className="govuk-caption-m">
                  Provide any information you have about the person.
                </span>
                <br />
                <span className="govuk-caption-m">
                If you do not know any details, you can leave the fields blank.
                </span>
                <br />
                <InputBox
                  question="Full name"
                  name="about_person.buyer.fullname"
                />
                <InputBox
                  question={questionTitles.about_person.buyer.email}
                  name="about_person.buyer.email"
                />
                <InputBox
                  question={questionTitles.about_person.buyer.telephone}
                  isNumber={true}
                  name="about_person.buyer.telephone"
                  size="20"
                  subText="For international numbers, include the country code. For example, +353"
                />
                <Date
                  question={questionTitles.about_person.buyer.dob}
                  name="about_person.buyer.dob"
                  subText="For example, 8 7 1980"
                />
                <InputBox
                  question="Billing address"
                  name="about_person.buyer.billing"
                />
                <InputBox
                  question="Delivery address"
                  name="about_person.buyer.delivery"
                />
                <TextArea
                  heading="What other information can you tell us about the person?"
                  name="about_person.buyer.description"
                  subText={
                    <div>
                      <p> For example:</p>
                      <ul>
                        <li>height, age, gender and ethnicity</li>
                        <li> voice or accent</li>
                        <li> identifiable features or marks</li>
                        <li>clothing</li>
                        <li>vehicle details</li>
                      </ul>
                      <p>
                        Were they alone? If not, can you describe their
                        companion?
                      </p>
                    </div>
                  }
                  style="m"
                  hintId="behaviour_hint"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test="button-continue"
                aria-label="Continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const BuyerInformationWithFormik = formikWrapper(BuyerInformation);

export { BuyerInformationWithFormik as BuyerInformation };
