import React, {useEffect} from 'react';
import {useMatomo} from "@datapunt/matomo-tracker-react";

const Cookies = () => {
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: 'Cookies - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Cookies Page',
      action: 'Visited',
    });
  }, [trackPageView, trackEvent]);

  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l">Cookies</h1>
          <p className="govuk-body">
            The Report Suspicious Chemical Activity Service puts small files
            (known as 'cookies') onto your computer to collect information about
            how you browse the site.
          </p>
          <p className="govuk-body">Cookies are used to:</p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>
                measure how you use the website so it can be updated and
                improved based on your needs
              </li>
              <li>
                remember the notifications you’ve seen so that we don’t show
                them to you again
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            Report Suspicious Chemical Activity cookies aren’t used to identify
            you personally.
          </p>
          <p className="govuk-body">
            You’ll normally see a message on the site before we store a cookie
            on your computer.
          </p>
          <p className="govuk-body">
            Find out more about how to manage cookies at{' '}
            <a
              href="https://www.aboutcookies.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              aboutcookies.org
            </a>
            .
          </p>
          <p className="govuk-body">
            <a href="/change-cookies">Change your cookie settings</a>.
          </p>
          <h2 className="govuk-heading-m">
            How cookies are used on this service
          </h2>
          <h3 className="govuk-heading-s">Measuring website usage (Matomo)</h3>
          <p className="govuk-body">
            We use Matomo software to collect information about how you use the
            Report Suspicious Chemical Activity Service. We do this to help make
            sure the service is meeting the needs of its users and to help us
            make improvements.
          </p>
          <p className="govuk-body">Matomo stores information about:</p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>the pages you visit</li>
              <li>how long you spend on each page</li>
              <li>how you got to the site</li>
              <li>what you click on while you’re visiting the site</li>
            </ul>
          </p>
          <p className="govuk-body">
            We don’t collect or store your personal information so this
            information can’t be used to identify who you are. For more
            information, visit our{' '}
            <a href="/privacy-policy" target="_blank">
              Privacy policy
            </a>
            .
          </p>
          <p className="govuk-body">
            We don’t allow Matomo to use or share our analytics data.
          </p>
          <p className="govuk-body">Matomo sets the following cookies:</p>
          <table className="govuk-table">
            <thead>
              <tr className="govuk-table__row">
                <th scope="col" className="govuk-table__header">
                  Name
                </th>
                <th scope="col" className="govuk-table__header">
                  Purpose
                </th>
                <th scope="col" className="govuk-table__header">
                  Expires
                </th>
              </tr>
            </thead>
            <tbody className="govuk-table__body">
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">_pk_id</td>
                <td className="govuk-table__cell">
                  Used to store a few details about you such as the unique
                  visitor ID
                </td>
                <td className="govuk-table__cell">13 months</td>
              </tr>
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">_pk_ref</td>
                <td className="govuk-table__cell">
                  Used to store the attribution information, the referrer
                  initially used to visit the website
                </td>
                <td className="govuk-table__cell">6 months</td>
              </tr>
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">
                  _pk_ses, _pk_cvar, _pk_hsr
                </td>
                <td className="govuk-table__cell">
                  Short lived cookies used to temporarily store data for your
                  visit
                </td>
                <td className="govuk-table__cell">30m</td>
              </tr>
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">_pk_testcookie</td>
                <td className="govuk-table__cell">
                  Used to check whether your browser supports cookies
                </td>
                <td className="govuk-table__cell">
                  Created and instantly deleted
                </td>
              </tr>
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">
                  mtm_consent (or mtm_consent_removed), mtm_cookie_consent
                </td>
                <td className="govuk-table__cell">
                  To remember that consent was given (or removed) by you
                </td>
                <td className="govuk-table__cell">30 years</td>
              </tr>
            </tbody>
          </table>
          <h2 className="govuk-heading-m">Our introductory message</h2>
          <p className="govuk-body">
            You may see a pop-up welcome message when you first visit GOV.UK.
            We’ll store a cookie so that your computer knows you’ve seen it and
            knows not to show it again.
          </p>
          <table className="govuk-table">
            <thead>
              <tr className="govuk-table__row">
                <th scope="col" className="govuk-table__header">
                  Name
                </th>
                <th scope="col" className="govuk-table__header">
                  Purpose
                </th>
                <th scope="col" className="govuk-table__header">
                  Expires
                </th>
              </tr>
            </thead>
            <tbody className="govuk-table__body">
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">seen_cookie_message</td>
                <td className="govuk-table__cell">
                  Saves a message to let us know that you have seen our cookie
                  message
                </td>
                <td className="govuk-table__cell">1 month</td>
              </tr>
            </tbody>
          </table>
          <h2 className="govuk-heading-m">
            Your progress when using the service
          </h2>
          <p className="govuk-body">
            When you use the Report Suspicious Chemical Activity Service, we’ll
            set a cookie to remember your progress through the forms. These
            cookies don’t store your personal data and are deleted once you’ve
            completed the report.
          </p>
          <table className="govuk-table">
            <thead>
              <tr className="govuk-table__row">
                <th scope="col" className="govuk-table__header">
                  Name
                </th>
                <th scope="col" className="govuk-table__header">
                  Purpose
                </th>
                <th scope="col" className="govuk-table__header">
                  Expires
                </th>
              </tr>
            </thead>
            <tbody className="govuk-table__body">
              <tr className="govuk-table__row">
                <td className="govuk-table__cell">session</td>
                <td className="govuk-table__cell">
                  An identifier for your session
                </td>
                <td className="govuk-table__cell">At the end of the session</td>
              </tr>
            </tbody>
          </table>
          <p className="govuk-body">
            You can read more about{' '}
            <a
              href="https://www.gov.uk/help/cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              how cookies are used on GOV.UK
            </a>
            .
          </p>
          <p className="govuk-body">
            Your session will time out after 30 minutes of inactivity. We do
            this for your security.
          </p>
        </div>
      </main>
    </>
  );
};

export { Cookies };
