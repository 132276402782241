export const escapeHtmlFromData = (report) => {
  if (!report) {
    return report;
  }
  return checkObject(report);
};

function checkObject(obj) {
  if (typeof obj === 'string') {
    return safeReplace(obj);
  }
  if (Array.isArray(obj)) {
    return checkArray(obj);
  }
  if (typeof obj === 'object') {
    for (let key of Object.keys(obj)) {
      let entry = obj[key];
      obj[key] = checkObject(entry);
    }
  }
  return obj;
}

function checkArray(arr) {
  for (let entry of arr) {
    if (Array.isArray(entry)) {
      entry = checkArray(entry);
    } else if (typeof entry === 'object') {
      entry = checkObject(entry);
    } else if (typeof entry === 'string') {
      entry = safeReplace(entry);
    }
  }
  return arr;
}

function safeReplace(str) {
  const tagsToReplace = {
    '<': '&lt;',
    '>': '&gt;',
  };

  return str.replace(/[<>]/g, (tag) => tagsToReplace[tag] || tag);
}
