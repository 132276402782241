import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { InputBox } from '../components';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction CustomerId',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('customer-id');
    if (values.editMode) {
      formikBag.props.updateValues(values, 'review-answers');
    } else if (
      values.transaction.type === 'Online - delivery' ||
      values.transaction.type === 'Online - delivery'
    ) {
      formikBag.props.updateValues(values, 'third-party');
    } else if (values.transaction.type !== 'In store') {
      formikBag.props.updateValues(values, 'third-party');
    } else {
      formikBag.props.updateValues(values, 'payment-method');
    }
  },
});

const CustomerId = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Customer ID - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Transaction CustomerId',
      action: 'Started',
    });
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`customer-id`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList />
            <span className="govuk-caption-l">Transaction details</span>
            <Form onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  name="transaction.customer_id"
                  subText="If it exists, provide the unique code you use to identify a customer in your database."
                  size="20"
                  hintId="customer-id-hint"
                  questionLarge="What was their customer ID?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const CustomerIdWithFormik = formikWrapper(CustomerId);

export { CustomerIdWithFormik as CustomerId };
