import React, { useContext } from 'react';
import { OneOffAuthContext } from '../../components/oneOffAuth/OneOffAuthContext';
import { StartButton } from '../../components/Govuk';

export const OneOffReport = () => {
  const { isOneOffAuthenticated } = useContext(OneOffAuthContext);

  return (
    <>
      <h1 className="govuk-heading-l" data-test="one-off-header">
        Make a one-off report
      </h1>
      <p className="govuk-body" data-test="one-off-body">
      Use this service to report suspicious chemical activity.
      </p>
      {' '}
      <p className="govuk-body" data-test="one-off-body">
      If you cannot report using a digital service, make a report to the anti-terrorist hotline on 0800 789 321. The hotline is available all day, every day.
      </p>
      <p className="govuk-body">
        {' '}
        <StartButton
        url={
            isOneOffAuthenticated() ? 'sar/before-starting' : 'sar-registration'
          }
        dataTest="data-start-button"
        >
          Submit a one-off suspicious activity report
        </StartButton>
      </p>
      <br />
    </>
  );
};
