import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { InputBox, Date } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { getTransactionDateValidationSchema } from '../validation-utils';

const questionTitles = {
  transaction: {
    time: 'Time',
  },
};

const DateTimeSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    ...getTransactionDateValidationSchema(),
  }),
});

const formikWrapper = withFormik({
  validationSchema: DateTimeSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Date/Time',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('transaction-date');
    if (values.editMode) {
      formikBag.props.updateValues(values, 'review-answers');
    } else if (
      values.about_incident.report_type === 'A theft' ||
      values.about_incident.report_type === 'A disappearance' ||
      values.about_incident.report_type === 'An attempted purchase' ||
      values.about_incident.report_type === 'A completed purchase'
    ) {
      formikBag.props.updateValues(values, 'activity-location');
    } else if (
      /*
    else if (values.about_incident.report_type === 'An attempted purchase') {
      formikBag.props.updateValues(values, 'payment-method');
    }
    */
      values.transaction.type === 'Online - delivery' ||
      values.transaction.type === 'Online - collect' ||
      values.transaction.type === 'Telephone - delivered' ||
      values.transaction.type === 'Other'
    ) {
      formikBag.props.updateValues(values, 'delivery-details');
    } else {
      formikBag.props.updateValues(values, 'payment-method');
    }
  },
});

const DateTime = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Transaction Date/Time - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Transaction Date/Time',
      action: 'Started',
    });
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`transaction-date`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList questionTitles={questionTitles} />
            <span className="govuk-caption-l">About the activity</span>
            <Form onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <Date
                  name="transaction.date"
                  subText="For example, 10 6 2022 or 17:15. If you do not know some details, you can leave these blank. Estimations are acceptable if you are unsure on the exact timings."
                  questionLarge="When did the suspicious activity take place?"
                />
                <InputBox
                  time={questionTitles.transaction.time}
                  name="transaction.time"
                  size="5"
                  isTime={true}
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const DateTimeWithFormik = formikWrapper(DateTime);

export { DateTimeWithFormik as DateTime };
