import React, {useEffect} from 'react';
import { InputBox } from '../OneOffSars/components';
import { generateUser } from './api';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_ERROR_MSG } from '../OneOffSars/validation-utils';
import {useMatomo} from "@datapunt/matomo-tracker-react";

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email(EMAIL_ERROR_MSG).required(EMAIL_ERROR_MSG),
});

const formikWrapper = withFormik({
  validationSchema: RegistrationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    generateUser(values.email)
      .then((res) => {
        if (res.status === 200) {
          formikBag.props.history.push('/sar-registration/complete');
        }
      })
      .catch((err) => {
        if (err.response.status === 406) {
          // not allowed
          formikBag.props.history.push('/sar-registration/not-allowed');
        } else {
          formikBag.props.history.push('/one-off-sars-error');
        }
      });
  },
});

const OneOffSarRegistration = () => {
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: 'One-off SAR Registration - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'One-off SAR Registration Page',
      action: 'Visited',
    });
  }, [trackPageView, trackEvent]);
  //pass flag variable to show variable has bold but use the same variable
  return (
    <div className="govuk-width-container">
      <main
        className="govuk-main-wrapper "
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <Form>
              <h1 className="govuk-heading-l" data-test="main-header-text">
                Link to a one-off report
              </h1>
              <p className="govuk-body" data-test="body-info-text">
              You will be emailed an access link to the one-off report form.
               If you have used this service before you will need to enter your email address again.
              </p>
              <p className="govuk-body" data-test="body-info-text">
              When you complete the form you will be emailed a copy of your report.
              You will have the opportunity to add extra emails later if you would like other people or 
              teams to receive a copy of your report.
              </p>
              <InputBox
                question="Email address"
                name={'email'}
                subText="Provide an email address you have access to"
              />
              <button
                id={'submit-email'}
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                aria-label="continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const OneOffSarRegistrationWithFormik = formikWrapper(OneOffSarRegistration);

export { OneOffSarRegistrationWithFormik as OneOffSarRegistration };
