import React, { useEffect } from 'react';
import { InputBox } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About the Incident - TheftCrimeReference',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'activity-date';
    if (values.about_person.person_known === 'Yes') {
      next = 'suspicious-person';
    }
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('crime-reference');
    formikBag.props.updateValues(values, next);
  },
});

const TheftCrimeReference = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();
  useEffect(() => {
    trackPageView({
      documentTitle: 'Crime Reference Number - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'About the Incident - TheftCrimeReference',
      action: 'Started',
    });
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`crime-reference`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <span className="govuk-caption-m">About the activity</span>
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  name="about_person.crime_details.crime_reference"
                  subText={
                    <div>
                      <p>
                        You receive a crime reference number from the police if
                        you report a theft.
                      </p>
                      <ul>
                        <li>
                          If you have already reported to the police and have
                          received a crime reference number, enter this below.
                        </li>
                        <li>
                          If you are still waiting for your crime reference
                          number or have not reported to police, you can leave
                          this blank.
                        </li>
                      </ul>
                    </div>
                  }
                  hintId="crime_reference"
                  questionLarge="What is your crime reference number?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const TheftCrimeReferenceWithFormik = formikWrapper(TheftCrimeReference);

export { TheftCrimeReferenceWithFormik as TheftCrimeReference };
