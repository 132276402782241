import axios from "axios";


const updateSession = (keycloak) => {
  sessionStorage.setItem('token', keycloak.token);
  sessionStorage.setItem('idToken', keycloak.idToken);
  sessionStorage.setItem('tokenData', JSON.stringify(keycloak.tokenParsed));
  sessionStorage.setItem('authenticated', keycloak.authenticated.toString());
};

/**
 * Display error page based on the current path
 * @param props
 */
const displayErrorPage = (props) => {
  if (window.location.pathname === '/sar-registration') {
    props.history.push('/one-off-sars-error');
  } else if (window.location.pathname.match(/^\/sar\//)) {
    props.history.push('/error/oneoff');
  } else if (window.location.pathname === '/previous-uploads') {
    props.history.push('/sar-error');
  } else {
    props.history.push('/error');
  }
};

function apply_session_token(request) {
  const token = sessionStorage.getItem('token');
  if (token) {
    request.headers.Authorization = token;
  }
  return request;
}

/**
 * Set up axios interceptors
 * - When making any API request, always attempt to refresh token first
 * - For all API responses, check for errors and redirect to error page if necessary
 * @param props
 */
const setupAxios = (props) => {
  const keycloak = props.keycloak;

  axios.interceptors.request.use(async (request) => {
    const isOneOffAuthenticated = sessionStorage.getItem('one-off-authenticated') === 'true';
    if (keycloak.authenticated || isOneOffAuthenticated) {
      return apply_session_token(request);
    } else {
      // If not authenticated, redirect to login page
      keycloak.clearToken();
      sessionStorage.clear();
      props.history.push('/login');
      return request;
    }
  });
  // Set up error handler here so it has access to router props to route to error page
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (window.location.pathname === '/upload' && error.response.status !== 401) {
          return Promise.reject(error);
        }
        switch (error.response.status) {
          case 500:
            displayErrorPage(props);
            break;
          case 401:
            sessionStorage.clear();
            props.history.push('/login');
            break;
          case 404:
            props.history.push('/not-found');
            break;
          default:
            props.history.push('/sar-error');
            break;
        }
      } else {
        displayErrorPage(props);
      }
    }
  );
};

export {
  setupAxios,
  displayErrorPage,
  updateSession,
}
