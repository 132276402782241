import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { InputBox, TextArea } from '../components';
import { ErrorList } from '../components/ErrorList';
import { defaultProductValues } from '../initial-values';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Product Information',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'product-review';
    if (
      values.editMode &&
      values.sections.has(`product-review.${values.productIndex}`)
    ) {
      next = 'review-answers';
    }
    values.sections.add(`product-information.${values.productIndex}`);
    formikBag.props.updateValues(values, next);
  },
});

const ProductInformation = ({
  handleSubmit,
  values,
  setFieldValue,
  updateValues,
}) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();
  const index = values.productIndex;
  const productNumber = index + 1;

  useEffect(() => {
    trackPageView({
      documentTitle: 'Product Information - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Product Information',
      action: 'Started',
    });

    const exists = values.products.filter((product) => product.id === index);
    if (exists.length === 0) {
      const newIndex = values.products.length;
      const newProduct = Object.assign({}, defaultProductValues);
      newProduct.id = newIndex;
      values.products.push(newProduct);
      values.productIndex = newIndex;
      updateValues(values);
    }
  }, [values.productIndex]);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`product-information.${index}`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <span className="govuk-caption-l no-margin">Product {productNumber}</span>
            <h1 className="govuk-label govuk-label--l no-margin">
              Product details
              <p className="govuk-caption-m custom-bottom-margin">
                Another product can be added after you complete these details
              </p>
            </h1>
            <ErrorList/>
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                    question="What is the trade name of the product?"
                    name={`products.${index}.product_name`}
                />
                <TextArea
                    question="What information do you know about this product?"
                    name={`products.${index}.product_description`}
                    subText={
                      <div>
                        <p>For example:</p>
                        <ul>
                          <li> what the product can be used for</li>
                          <li>the name of the chemical in the product</li>
                          <li>the concentration of the chemical</li>
                        </ul>
                        <p>
                          You can use the description from the product's label or
                          safety data sheet (section 3 may be helpful).
                        </p>
                        <p>If you do not know, you can leave this blank.</p>
                      </div>
                    }
                    hintId="product-description-hint"
                />
                <InputBox
                    name={`products.${index}.product_quantity`}
                    subText="For example, 3 bottles of 750ml or 500 tonnes.  If you do not know, you can leave this blank."
                    question="What quantity or volume of the product was involved?"
                    isList={true}
                    size="20"
                    hintId="product-quantity-hint"
                />
              </div>
              <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label="continue"
                  name={'product.details'}
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ProductInformationWithFormik = formikWrapper(ProductInformation);

export {ProductInformationWithFormik as ProductInformation};
