import React from 'react';

export const Contact = () => {
  return (
    <>
      <h1 className="govuk-heading-l" data-test="contact-header">
        Contact
      </h1>
      <p className="govuk-body" data-test="contact-body">
        For questions about reporting, email {' '}
        <a
          href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
          data-test="contact-crt-email"
        >
          ChemicalReportingTeam@homeoffice.gov.uk
        </a>
        <br />
        <br />
        This email is monitored Monday to Friday, 9am to 5pm (except bank holidays).
      </p>
    </>
  );
};
