import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ErrorList } from '../components/ErrorList';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Remove Confirm',
      action: 'Completed',
    });

    let isLastRemoved =
      values.productRemoveIndex === values.products.length - 1;
    values.products.splice(values.productRemoveIndex, 1);
    if (!isLastRemoved) {
      values.products.forEach((product, i) => (product.id = i));
      values.productIndex = values.products.length - 1;
    }

    formikBag.props.updateValues(values, 'product-review');
  },
});

const RemoveConfirm = ({
  handleSubmit,
  values,
  formQuestions,
  updateValues,
}) => {
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Remove Confirm - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Remove Confirm',
      action: 'Started',
    });
  }, []);

  const onCancel = (e) => {
    e.preventDefault();
    updateValues(values, 'product-review');
  };

  return (
    <div>
      <a className="govuk-back-link" href="#" onClick={() => history.goBack()}>
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-label-wrapper">
              <label className="govuk-label govuk-label--l">
                Remove this product?
              </label>
            </h1>
            <ErrorList questions={formQuestions} />
            <form onSubmit={handleSubmit}>
              <p className="govuk-body govuk-!-margin-bottom-9">
                This can't be undone
              </p>
              <div className="govuk-button-group">
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label="continue"
                >
                  Confirm
                </button>
                <button
                  data-prevent-double-click="true"
                  className="govuk-button govuk-button--secondary"
                  data-module="govuk-button"
                  data-test={`button-cancel`}
                  aria-label="cancel-remove-product"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const RemoveConfirmWithFormik = formikWrapper(RemoveConfirm);

export { RemoveConfirmWithFormik as RemoveConfirm };
