import React, {useEffect} from 'react';
import {useMatomo} from "@datapunt/matomo-tracker-react";

const Help = () => {
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: 'Help - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Help Page',
      action: 'Visited',
    });
  }, []);
  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l" data-test="help-header">
            Help
          </h1>
          <p className="govuk-body" data-test="help-p1">
            For questions about what to report and how to submit a report, or
            for any technical questions, please email{' '}
            <a
              data-test="help-email"
              href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
            >
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
            .
          </p>
          <p className="govuk-body" data-test="help-p2">
            This inbox is accessed by staff Monday to Friday from 9.00am to
            5.00pm and is not monitored on bank holidays.
          </p>
          <p className="govuk-body" data-test="help-p3">
            If the team are unavailable or your matter is urgent, call the
            Anti-Terrorist Hotline on{' '}
            <a data-test="help-phone" href="tel:0800789321">
              0800 789 321
            </a>{' '}
            to make your report or contact your local police force.
          </p>
        </div>
      </main>
    </>
  );
};

export { Help };
