import React from 'react';

const AccessibilityStatement = () => {
  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-xl">
            Accessibility statement for the Report suspicious chemical activity
            service
          </h1>
          <p className="govuk-body">
            This accessibility statement applies to the Report suspicious chemical activity service.
          </p>
          <p className="govuk-body">
            This website is run by the Home Office. It allows members of the public to report suspicious
            activity involving explosives precursors and poisons. This includes suspicious purchases,
            attempted purchases, disappearances, and thefts.
          </p>
          <p className="govuk-body">
            We want as many people as possible to be able to use this website. For example, that means
            you should be able to:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>change colours, contrast levels and fonts.</li>
              <li>
                zoom in up to 200% without the text spilling off the screen.
              </li>
              <li>navigate most of the website using just a keyboard.</li>
              <li>
                navigate most of the website using speech recognition software.
              </li>
              <li>
                listen to most of the website using a screen reader (including
                the most recent versions of JAWS, NVDA and VoiceOver).
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            We’ve also made the website text as simple as possible to
            understand.
          </p>
          <p className="govuk-body">
            {' '}
            <a
                href="https://mcmw.abilitynet.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
            >
              AbilityNet
            </a>{' '}
            has advice on making your device easier to use if you have a
            disability.
          </p>
          <h2 className="govuk-heading-m">How accessible this website is</h2>
          <p className="govuk-body">
            We know some parts of this website are not fully accessible. You can
            find the full list of known issues about in the ‘non-accessible content’
            section of this statement.
          </p>
          <h3 className="govuk-heading-s">Feedback and contact information</h3>
          <p className="govuk-body">
            If you need information on this website in a different format like
            accessible PDF, large print, easy read, audio recording or braille,
            email{' '}
            <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
          </p>
          <h2 className="govuk-heading-s">
            Reporting accessibility problems with this website
          </h2>
          <p className="govuk-body">
            We’re always looking to improve the accessibility of this website.
            If you find any problems not listed on this page or think we’re not
            meeting accessibility requirements, email {' '}
            <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
            &nbsp;and let us know:
          </p>
          <div className={`extra-indent`}>
            <p className="govuk-body">
              <ul className="govuk-list govuk-list--bullet">
                <li>the URL where you found the problem</li>
                <li>
                  what the problem is - for example, a wording which is difficult
                  to understand
                </li>
                <li>
                  what computer and software you’re using, and whether you use any
                  assistive technology
                </li>
              </ul>
            </p>
          </div>
          <p className="govuk-body">
            <a
                href="http://www.w3.org/WAI/users/inaccessible"
                target="_blank"
                rel="noopener noreferrer"
            >
              Read tips on contacting organisation about inaccessible websites.
            </a>
          </p>
          <h2 className="govuk-heading-m">
            Technical information about this website’s accessibility
          </h2>
          <p className="govuk-body">
            The Home Office is committed to making its website accessible, in
            accordance with the Public Sector Bodies (Websites and Mobile Applications)
            (No. 2) Accessibility Regulations 2018.
          </p>
          <h3 className="govuk-heading-s">Compliance status</h3>
          <p className="govuk-body">
            This website has been tested against the Web Content Accessibility Guidelines
            (WCAG) 2.2 AA standard.
          </p>
          <p className="govuk-body">
            This website is partially compliant with the{' '}
            <a
                href="https://www.w3.org/TR/WCAG22/"
                target="_blank"
                rel="noopener noreferrer"
            >
              Web Content Accessibility Guidelines version 2.2
            </a>{' '}
            AA standard, due to the non-compliances and exemptions listed below.
          </p>
          <h3 className="govuk-heading-s">Non-accessible content</h3>
          <p className="govuk-body">
            The content listed below is non-accessible for the following
            reasons.
          </p>
          <p className="govuk-body">
            Some headings are not programmatically marked correctly, for example a
            &lt;h2&gt; heading is marked as a paragraph &lt;p&gt;. This misrepresents the
            structure for users relying on screen readers. This fails WCAG 2.2
            success criterion 1.3.1 (Info and Relationships).
          </p>
          <p className="govuk-body">
            We plan to update all markings so that they accurately represent the content
            structure by 1st June 2025.
          </p>
          <p className="govuk-body">
            A user will timeout and lose their progress if they have not submitted the
            form after 24 hours. They will have to start the form from the beginning.
            This fails WCAG 2.2 success criterion 2.2.5 (Re-authenticating).
          </p>
          <p className="govuk-body">
            We do not plan to resolve this issue as the form can be completed in one sitting.
          </p>
          <p className="govuk-body">
            Some links do not have descriptive names that accurately explain what the purpose
            of the link is, meaning screen reader users can’t easily understand what is expected
            to happen when the link is selected. This fails WCAG 2.2 success criterion 2.4.4
            (Link Purpose (In Context)).
          </p>
          <p className="govuk-body">
            We plan to amend all non-descriptive link names by 1st June 2025.
          </p>
          <p className="govuk-body">
            The feedback link opens out in a new tab when clicked, whereas all other links open
            out in the same tab when clicked. Screen reader users may be unaware that they have
            opened the link in a new tab. This fails WCAG 2.2 success criterion 3.2.2 (On Input)
            and 3.2.5 (Change on Request).
          </p>
          <p className="govuk-body">
            We will review best practice for online forms and implement any changes, such as
            notifying users that the link will open in a new tab, by 1st June 2025.
          </p>
          <p className="govuk-body">
            Some form labels do not have an associated form control, which can confuse screen
            readers. This fails WCAS 2.2 success criterion 4.1.2 (Name, Role, Value).
          </p>
          <p className="govuk-body">
            We plan to add associated form controls for all form labels by 1st June 2025.
          </p>
          <h3 className="govuk-heading-s">Disproportionate burden</h3>
          <p className="govuk-body">
            At this time, we have not made any disproportionate burden claims.
          </p>
          <h3 className="govuk-heading-s">
            Content that’s not within the scope of the accessibility regulations
          </h3>
          <p className="govuk-body">
            Our service requires users to complete a form. The accessibility standards
            do not require our links to be opened out in the same tab because doing so
            will lose a user’s inputted answers.
          </p>
          <p className="govuk-body">
            We will review the consistency of our link behaviour and ensure that users are
            warned, where possible, about links opening in a new tab.
          </p>
          <h2 className="govuk-heading-m">
            What we’re doing to improve accessibility
          </h2>
          <p className="govuk-body">
            We are currently testing our website to understand where we are non-compliant
            with accessibility standards. Any non-compliant components will be raised in
            our roadmap and improved in the coming months.
          </p>
          <h3 className="govuk-heading-s">
            Preparation of this accessibility statement
          </h3>
          <p className="govuk-body">
            This statement was prepared on 5 March 2025 It was last reviewed on 5 March 2025.
          </p>
          <p className="govuk-body">
            This website was last tested on 28 February 2025. Testing was carried out internally
            by the Home Office.
          </p>
          <p className="govuk-body">
            We tested all the screens of the service.
          </p>
          <h2 className="govuk-heading-m">Enforcement procedure</h2>
          <p className="govuk-body">
            The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public
            Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018
            (the ‘accessibility regulations’). If you’re not happy with how we respond to your
            complaint, {' '}
            <a
                href="https://www.equalityadvisoryservice.com/"
                target="_blank"
                rel="noopener noreferrer"
            >
              contact the Equality Advisory and Support Service (EASS).
            </a>
          </p>
          <p className="govuk-body">
            If you are in Northern Ireland and are not happy with how we respond
            to your complaint you can contact the {' '}
            <a
                href="https://www.equalityni.org/Home"
                target="_blank"
                rel="noopener noreferrer"
            >
              Equalities Commission for Northern Ireland
            </a>{' '}
            who are responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications)
            (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’) in Northern Ireland.
          </p>
        </div>
      </main>
    </>
  );
};

export {AccessibilityStatement};
