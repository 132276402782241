import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useMatomo} from "@datapunt/matomo-tracker-react";

export const OneOffSarRegistrationComplete = () => {
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: 'One-off SAR Registration Complete - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'One-off SAR Registration Complete Page',
      action: 'Visited',
    });
  }, [trackPageView, trackEvent]);
  return (
    <main id="content" role="main" tabIndex={0}>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <div className="govuk-panel govuk-panel--confirmation">
            <h1 className="govuk-panel__title" data-test="main-panel-text">
              Registration
              <br />
              Complete
            </h1>
          </div>
          <div className="govuk-body" data-test="body-info-text">
            <p>We have sent you a confirmation email.</p>
            <b>What happens next</b>
            <p>
              Follow the instructions in the email to access the one-off report.
            </p>
            <p>
              If you haven't received an email within 5 minutes, check your junk
              inbox or {' '}
              <Link to="/sar-registration">enter your details again</Link>.
            </p>
            <p className="govuk-body" data-test="contact-body">
              If you need support, contact the team at{' '}
              <a
                href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
                data-test="contact-crt-email"
              >
                ChemicalReportingTeam@homeoffice.gov.uk
              </a>
            </p>
          </div>
          <p className="govuk-body" data-test="return-to-login-link">
            <Link to="/login">Return to the home page</Link>
          </p>
        </div>
      </div>
    </main>
  );
};
