import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { InputBox } from '../components';
import { NUMBER_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const createQuestionTitles = (index, values) => {
  let products = [];
  const productIndex = values.productIndex;
  const unitEntered =
    values.products[productIndex].product_units === 'Other'
      ? values.products[productIndex].product_units_other
      : values.products[productIndex].product_units;
  for (let i = 0; i < index; i++) {
    products.push({
      volume: `How many ${unitEntered.toLowerCase()} are there per item?`,
      quantity_sold: 'How many items are you reporting?',
    });
  }
  return {
    products,
  };
};

const ProductInformationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      volume: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
      quantity_sold: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    })
  ),
});

const formikWrapper = withFormik({
  validationSchema: ProductInformationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Item',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'chemical';
    if (
      values.editMode &&
      values.sections.has(`product-review.${values.productIndex}`)
    ) {
      next = 'review-answers';
    }
    values.sections.add(`item-details.${values.productIndex}`);
    formikBag.props.updateValues(values, next);
  },
});

const Item = ({ handleSubmit, values, setFieldValue }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Quantity and Volume - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Item',
      action: 'Started',
    });
  }, []);

  const index = values.productIndex;
  const productNumber = index + 1;
  const questionTitles = createQuestionTitles(productNumber, values);
  const exists = values.products.filter((product) => product.id === index);
  if (exists.length === 0) {
    history.goBack();
    return <></>;
  }

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`item-details.${index}`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <span className="govuk-caption-l">Product {productNumber}</span>
            <h1 className="govuk-label-wrapper">
              <label className="govuk-label govuk-label--l">
                Quantity and volume
              </label>
            </h1>
            <ErrorList questionTitles={questionTitles} />
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  question={questionTitles.products[index].quantity_sold}
                  isNumber={true}
                  name={`products.${index}.quantity_sold`}
                  size="5"
                />
                <InputBox
                  question={questionTitles.products[index].volume}
                  isNumber={true}
                  name={`products.${index}.volume`}
                  size="5"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ItemWithFormik = formikWrapper(Item);

export { ItemWithFormik as Item };
