import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { OneOffAuthContext } from '../oneOffAuth/OneOffAuthContext';
import { useHistory } from 'react-router-dom';

const withAuthorisation =
  ({ forwardUrl = '/', isLoginPage = false, registeredOnly = true }) =>
  (RequestedPage) =>
  (props) => {
    const { isAuthenticated, isOneOffUser } = useContext(AuthContext);
    const { isOneOffAuthenticated } = useContext(OneOffAuthContext);
    const history = useHistory();

    if (isLoginPage && !isAuthenticated()) {
      return <RequestedPage {...props} />;
    }
    if (isLoginPage && isAuthenticated()) {
      props.history.push(forwardUrl);
      return <></>;
    }

    if (isAuthenticated() && !isOneOffUser()) {
      return <RequestedPage {...props} />;
    } else if ((isOneOffAuthenticated() || isOneOffUser()) && !registeredOnly) {
      return <RequestedPage {...props} />;
    } else if ((isOneOffAuthenticated() || isOneOffUser()) && registeredOnly) {
      history.push('/unauthorised');
      return <></>;
    } else if (!isAuthenticated()) {
      history.push('/login');
      return <></>; // Return empty tags as forwarding to key cloak server to handle login
    }
  };

export default withAuthorisation;
