import React, { useEffect } from 'react';
import { Enum } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { SELECT_KNOWS_PERSONS_DETAILS_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const PersonKnownSchema = Yup.object().shape({
  about_person: Yup.object().shape({
    person_known: Yup.string().required(
      SELECT_KNOWS_PERSONS_DETAILS_ERROR_MESSAGE
    ),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: PersonKnownSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About the Person - Details Known',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('about-person-details');
    formikBag.props.updateValues(values, 'payment-method');
  },
});

const PersonKnown = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  useEffect(() => {
    trackPageView({
      documentTitle: 'Details Known - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'About the Person - Details Known',
      action: 'Started',
    });
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`about-person-details`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList />
            <span className="govuk-caption-l">About the person</span>
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[{ value: 'Yes' }, { value: 'No' }]}
                  name="about_person.person_known"
                  subText="Details may include their name, address, voice or a description of their appearance."
                  direction="col"
                  questionLarge="Do you know any details about the suspicious person(s) you are reporting?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const PersonKnownWithFormik = formikWrapper(PersonKnown);

export { PersonKnownWithFormik as PersonKnown };
