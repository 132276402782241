import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { Enum } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { THIRD_PARTY_OPTION_REQUIRED } from '../validation-utils';

const ThirdPartySchema = Yup.object().shape({
  transaction: Yup.object().shape({
    was_third_party: Yup.string().required(THIRD_PARTY_OPTION_REQUIRED),
  }),
});

const formikWrapper = withFormik({
  validationSchema: ThirdPartySchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Third Party',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('third-party');
    if (values.editMode) {
      formikBag.props.updateValues(values, 'review-answers');
    } else if (values.transaction.was_third_party === 'Yes') {
      formikBag.props.updateValues(values, 'vendor-name');
    } else {
      formikBag.props.updateValues(values, 'transaction-date');
    }
  },
});

const ThirdParty = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Third Party Channels - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Transaction Third Party',
      action: 'Started',
    });
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`third-party`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList />
            <span className="govuk-caption-l">Transaction details</span>
            <Form onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[{ value: 'Yes' }, { value: 'No' }]}
                  name="transaction.was_third_party"
                  direction="col"
                  questionLarge="Did you sell the products through a third party channel?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ThirdPartyWithFormik = formikWrapper(ThirdParty);

export { ThirdPartyWithFormik as ThirdParty };
