import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { InputBox, TextArea, Date } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  getDeliveryDateValidationSchema,
  getDispatchDateValidationSchema,
} from '../validation-utils';

const questionTitles = {
  transaction: {
    delivery_service_provider: 'Who was the delivery service provider?',
    delivery_address:
      "If delivered to an address that didn't belong to the recipient please provide details.",
    tracking_id: 'Delivery tracking ID',
    dispatch_date: 'Dispatch date',
    delivery_date: 'Delivery date',
    order_id: 'What was the transaction ID?',
    invoice_number: 'What was the invoice number?',
  },
};

const DeliveryDetailsSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    ...getDispatchDateValidationSchema(),
    ...getDeliveryDateValidationSchema(),
  }),
});

const formikWrapper = withFormik({
  validationSchema: DeliveryDetailsSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Delivery Details',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'payment-method';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('delivery-details');
    formikBag.props.updateValues(values, next);
  },
});

const DeliveryDetails = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Delivery Details - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Transaction Delivery Details',
      action: 'Started',
    });
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`delivery-details`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList questionTitles={questionTitles} />
            <Form onSubmit={formik.handleSubmit}>
              <fieldset className="govuk-fieldset">
                <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                  <span className="govuk-caption-l">Transaction details</span>
                  <h1 className="govuk-fieldset__heading">Delivery details</h1>
                </legend>
                <div className="govuk-grid-column">
                  <InputBox
                    question={
                      questionTitles.transaction.delivery_service_provider
                    }
                    name="transaction.delivery_service_provider"
                    size="20"
                  />
                  <TextArea
                    question={questionTitles.transaction.delivery_address}
                    name="transaction.delivery_address"
                  />
                  <InputBox
                    question={questionTitles.transaction.tracking_id}
                    name="transaction.tracking_id"
                    size="20"
                  />
                  <Date
                    question={questionTitles.transaction.dispatch_date}
                    name="transaction.dispatch_date"
                    subText="For example, 10 6 2021. If you do not know, you can leave this blank."
                  />
                  <Date
                    question={questionTitles.transaction.delivery_date}
                    name="transaction.delivery_date"
                    subText="For example, 10 6 2021. If you do not know, you can leave this blank."
                  />
                  <InputBox
                    question={questionTitles.transaction.order_id}
                    name="transaction.order_id"
                    size="20"
                  />
                  <InputBox
                    question={questionTitles.transaction.invoice_number}
                    name="transaction.invoice_number"
                    size="20"
                  />
                </div>
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label={'Continue'}
                >
                  Continue
                </button>
              </fieldset>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const DeliveryDetailsWithFormik = formikWrapper(DeliveryDetails);

export { DeliveryDetailsWithFormik as DeliveryDetails };
