import React from 'react';
import {Link} from "react-router-dom";

const OneOffSarsError = () => {
  return (
    <main
      className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
      id="content"
      role="main"
      tabIndex={0}
    >
      <h1 data-test="error-header">
        Sorry, there is a problem
      </h1>
      <p data-test="error-text-1">
        We were unable to validate your request. If you used an email magic link, please try{' '}
        <Link to="sar-registration">resubmitting your email address</Link>.
      </p>
      <p data-test="error-text-2">
        Alternatively, make a report to the Anti-Terrorist Hotline on{' '}
        <a href="tel:+0800789321">0800 789 321</a>.
      </p>
      <p data-test="error-text-3">
        Contact the{' '}
        <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
          Chemical Reporting Team
        </a>{' '}
        if you require further support. The inbox is monitored by the team
        Monday to Friday 9am to 5pm. It is not monitored on bank holidays.
      </p>
    </main>
  );
};
export default OneOffSarsError;
