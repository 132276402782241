import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';

export const InputBox = ({
  heading,
  question,
  subText,
  isNumber,
  name,
  size,
  isTime,
  hintId,
  questionLarge,
  textDescription,
  time,
}) => {
  const formik = useFormikContext();
  const getType = () => {
    if (isTime) {
      return 'time';
    }
  };
  const [error, setError] = useState();
  useEffect(() => {
    setError(get(formik.errors, name));
  }, [formik.errors, get, name]);

  return (
    <>
      <div
        className={classNames('govuk-form-group', {
          'govuk-form-group--error': error,
        })}
      >
        {heading && (
          <legend className="govuk-fieldset__legend govuk-fieldset__legend--m">
            <h2 className="govuk-heading-m">{heading}</h2>
          </legend>
        )}
        {questionLarge && (
          <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
            <h1 className="govuk-fieldset__heading">
              <label
                className="govuk-label govuk-label--l"
                htmlFor={name}
                id={`label-${name}`}
              >
                {questionLarge}
              </label>
            </h1>
          </legend>
        )}
        {question && (
          <label
            className="govuk-label govuk-label--m"
            htmlFor={`${name}`}
            id={`label-${name}`}
          >
            {question}
          </label>
        )}
        {time && (
          <label
            className="govuk-label"
            htmlFor={`${name}`}
            id={`label-${name}`}
          >
            {time}
          </label>
        )}
        {textDescription && (
          <label
            className="govuk-label"
            htmlFor={`${name}`}
            id={`label-${name}`}
          >
            {textDescription}
          </label>
        )}
        {subText && (
          <div id={hintId} className="govuk-hint">
            {subText}
          </div>
        )}
        {error && (
          <span id={`${name}.error`} className="govuk-error-message">
            <span className="govuk-visually-hidden">Error:</span>
            {error}
          </span>
        )}
        <Field
          className={classNames('govuk-input', {
            [`govuk-input--width-${size}`]: size,
            'govuk-input--error': error,
          })}
          id={`${name}`}
          name={`${name}`}
          data-test={`${name}`}
          type={getType()}
          inputMode={isNumber ? 'numeric' : 'text'}
          aria-describedby={hintId}
        />
      </div>
    </>
  );
};
