import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';

export const TextArea = ({
  question,
  name,
  subText,
  style,
  hintId,
  heading,
  headingLarge,
  maxChars = 0,
}) => {
  const formik = useFormikContext();
  const [remainingChars, setRemainingChars] = useState(1200);
  const [error, setError] = useState();
  const handleFeedbackTextChange = async (event) => {
    const currentText = event.target.value;
    const remaining = maxChars - currentText.length;
    setRemainingChars(remaining);
    await formik.setFieldValue(name, currentText);
  };
  useEffect(() => {
    setError(get(formik.errors, name));
  }, [formik.errors, get, name]);
  return (
    <>
      <div
        className={classNames('govuk-form-group', {
          'govuk-form-group--error': error,
        })}
      >
        {headingLarge && (
          <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
            <h1 className="govuk-fieldset__heading">
              <label className="govuk-label govuk-label--l" htmlFor={name}>
                {headingLarge}
              </label>
            </h1>
          </legend>
        )}
        {heading && (
          <legend className="govuk-label-wrapper">
            <h2 className="govuk-label">
              <label className="govuk-label govuk-label--m" htmlFor={name}>
                {heading}
              </label>
            </h2>
          </legend>
        )}
        {question && (
          <legend
            className={classNames('govuk-fieldset__legend', {
              'govuk-fieldset__legend--l': style !== 'm',
              'govuk-fieldset__legend--m': style === 'm',
            })}
          >
            <label className="govuk-label govuk-label--m" htmlFor={name}>
              {question}
            </label>
          </legend>
        )}
        {subText && (
          <div id={hintId} className="govuk-hint">
            {subText}
          </div>
        )}
        {error && (
          <span id={`${name}.error`} className="govuk-error-message">
            <span className="govuk-visually-hidden">Error:</span>
            {error}
          </span>
        )}
        <Field
          as={'textarea'}
          className="govuk-textarea"
          id={name}
          name={name}
          data-test={name}
          rows="8"
          aria-describedby={hintId}
          onChange={handleFeedbackTextChange}
        />
        {maxChars > 0 && (
          <span className="govuk-caption-m">
            Remaining characters: {remainingChars}
          </span>
        )}
      </div>
    </>
  );
};
