import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { InputBox, Enum } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { NUMBER_ERROR_MESSAGE } from '../validation-utils';

const questionTitles = {
  transaction: {
    price: 'Price of all items purchased',
  },
};

const PriceSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    price: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  }),
});

const formikWrapper = withFormik({
  validationSchema: PriceSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Price',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('price');
    if (values.editMode) {
      formikBag.props.updateValues(values, 'review-answers');
    } else {
      formikBag.props.updateValues(values, 'transaction-information');
    }
  },
});

const Price = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Total price - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Transaction Price',
      action: 'Started',
    });
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`price`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList questionTitles={questionTitles} />
            <Form onSubmit={formik.handleSubmit}>
              <span className="govuk-caption-l">Transaction details</span>
              <div className="govuk-grid-column">
                <InputBox
                  isNumber={true}
                  name="transaction.price"
                  size="10"
                  subText="Enter the total price of all items purchased by the individual, including any that weren't suspicious."
                  hintId="price-hint"
                  questionLarge={questionTitles.transaction.price}
                />
                <Enum
                  question="What currency is this product sold in?"
                  enumValues={[
                    { value: 'GBP' },
                    { value: 'EUR' },
                    { value: 'USD' },
                    {
                      value: 'Other',
                      conditionalName: `transaction.currency_other`,
                      conditionalLabel: 'Enter the currency',
                    },
                  ]}
                  name="transaction.currency"
                  direction="col"
                  values={values}
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const PriceWithFormik = formikWrapper(Price);

export { PriceWithFormik as Price };
