import React, { useEffect} from 'react';
import { InputBox } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { EMAIL_ERROR_MSG, validateEmail } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const questionTitles = {
  transaction: {
    additional_email: 'Email address'
  }
};

const AdditionalEmailSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    additional_email: Yup.string().email(EMAIL_ERROR_MSG),
    //This field has been added to schema just for the purpose of showing validation errors of dynamic fields.
    custom_field_for_error_msg: Yup.string()
  }),
});



const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: AdditionalEmailSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Additional emails',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('additional-email');
    formikBag.props.updateValues(values, 'review-answers');
  },
});

const AdditionalEmail = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  values.additional_emails = !values.additional_emails
    ? []
    : values.additional_emails;  
  useEffect(() => {
    trackPageView({
      documentTitle: 'Receive a copy of your report - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Additional emails',
      action: 'Started',
    });
  }, []);

  const onAddEmail = async (e) => {
    e.preventDefault();

    const emailValue = values.transaction.additional_email;

    // Explicitly check for an empty value
    if (!emailValue || emailValue.length < 1) {
      //Manually set validation error for empty value.
      formik.setFieldError("transaction.additional_email", "The email field is empty");
      return; // Stop further execution if the field is empty
    }

    // Manually validate the email address as the Formik validation cannot be forced on optional fields.
    const validEmail = validateEmail(emailValue);

    if (validEmail) {
      // Add the email if it's valid      
      values.additional_emails.push(values.transaction.additional_email);
      setFieldValue(values, 'additional-emails');
      //Set the field to empty
      values.transaction.additional_email = '';
      //Clear all validation errors
      formik.setErrors({});       
    }
    else {
      //Manually set validation error for invalid format.
      formik.setFieldError("transaction.additional_email", EMAIL_ERROR_MSG);
      return;
    }

  };
  const onShowEmail = (e, index) => {
    e.preventDefault();
    const emailEditEl = document.getElementById(`additional-email-edit-${index}`);
    const emailDisplayEl = document.getElementById(`additional-email-display-${index}`);
    const isEditing = emailEditEl.style.display === '' || emailEditEl.style.display === 'block';
    emailEditEl.style.display = isEditing ? 'none' : 'block';
    emailDisplayEl.style.display = isEditing ? 'block' : 'none';
  };
  const onChangeEmail = (e, i) => {
    e.preventDefault();
    const changedEmailEl = document.getElementById(`additional-email-${i}`);
    const changedEmail = changedEmailEl.value;
    if(validateEmail(changedEmail)){
      values.additional_emails[i] = changedEmail;
      setFieldValue(values, 'additional-emails');
      const emailEditEl = document.getElementById(`additional-email-edit-${i}`);
      const emailDisplayEl = document.getElementById(`additional-email-display-${i}`);
      emailEditEl.style.display = 'none';
      emailDisplayEl.style.display = '';  
      //Clear all validation errors  
      formik.setErrors({});
    }    
    else{    
      //Manually set validation error for invalid format. 
      formik.setFieldError(`transaction.custom_field_for_error_msg`, EMAIL_ERROR_MSG);     
      changedEmailEl.focus();
      return;
    }
  } 
  const onRemoveEmail = (e, i) => {
    e.preventDefault();
    values.additional_emails.splice(i, 1);
    setFieldValue(values, 'additional-emails');
  };
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`additional-emails`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList questionTitles={questionTitles} />
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <h1 className="govuk-heading-l">
                  Receive a copy of your report
                </h1>
                <InputBox
                  textDescription="If you would like other people or teams to receive a copy of your report, you can add their emails below"
                  name="transaction.additional_email"
                />
                <button
                  data-prevent-double-click="true"
                  className="govuk-button govuk-button--secondary"
                  data-module="govuk-button"
                  data-test={`button-add-email`}
                  aria-label="add-additional-emails"
                  onClick={onAddEmail}
                >
                  Add email
                </button>
              </div>
              {values.additional_emails.length > 0 && (
                <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                  {values.additional_emails.map((email, index) => (
                    <div
                      className="govuk-summary-list__row"
                      key={`additonal-emails-summary-${index}`}
                    >
                      <dt className="govuk-summary-list__key" style={{width:'10%'}}>{`${index + 1
                        }`}</dt>
                      <dd
                        className="govuk-summary-list__value"
                        data-test={`additional-email-${index}`}
                      >
                        <div id={`additional-email-edit-${index}`} style={{ display: 'none' }}>
                          <input type="text" id={`additional-email-${index}`} class="govuk-input" name={`additional-email-${index}`} defaultValue={email} />
                          <a
                            className="govuk-link"
                            href=""
                            onClick={(e) => onChangeEmail(e, index)}
                            id={`additional-email-save-${index}`}
                            data-test="change-email"                            
                          >
                            Save
                          </a>
                        </div>
                        <p id={`additional-email-display-${index}`} style={{ display: '' }}>{email}</p>
                      </dd>
                      <dd className="govuk-summary-list__actions">
                        <a
                          className="govuk-link"
                          href=""
                          onClick={(e) => onShowEmail(e, index)}
                          data-test={`show-change-email-${index}`}
                        >
                          Change
                        </a>                                                                                          
                      </dd>
                      <dd className="govuk-summary-list__actions">
                      <a
                          className="govuk-link"
                          href=""
                          onClick={(e) => onRemoveEmail(e, index)}
                          data-test={`remove-email-${index}`}
                        >
                          Remove
                        </a>
                      </dd>
                    </div>
                  ))}
                </dl>
              )}
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const AdditionalEmailWithFormik = formikWrapper(AdditionalEmail);

export { AdditionalEmailWithFormik as AdditionalEmail };
