import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Enum } from '../components';
import { ErrorList } from '../components/ErrorList';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Unit',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'cost';
    if (
      values.products[values.productIndex].weight_or_item ===
      'By weight or volume'
    ) {
      next = 'weight-details';
    }
    if (
      values.products[values.productIndex].weight_or_item === 'As single items'
    ) {
      next = 'item-details';
    }
    if (
      values.products[values.productIndex].weight_or_item ===
      'In sales units containing several of the same item'
    ) {
      next = 'multiple-item-details';
    }
    if (
      values.editMode &&
      values.sections.has(`product-review.${values.productIndex}`)
    ) {
      next = 'review-answers';
    }
    values.sections.add(`unit-details.${values.productIndex}`);
    formikBag.props.updateValues(values, next);
  },
});

const Unit = ({ handleSubmit, values, setFieldValue }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Unit - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Unit',
      action: 'Started',
    });
  }, []);

  const index = values.productIndex;
  const productNumber = index + 1;
  const exists = values.products.filter((product) => product.id === index);
  if (exists.length === 0) {
    history.goBack();
    return <></>;
  }

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`unit-details.${index}`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <span className="govuk-caption-l">Product {productNumber}</span>
            <h1 className="govuk-label-wrapper">
              <label className="govuk-label govuk-label--l">
                Product Information
              </label>
            </h1>
            <ErrorList />
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  question="What unit is this product sold in?"
                  enumValues={[
                    { value: 'Millilitres' },
                    { value: 'Litres' },
                    { value: 'Pints' },
                    { value: 'Gallons' },
                    { value: 'Milligrams' },
                    { value: 'Grams' },
                    { value: 'Kilograms' },
                    { value: 'Tonnes' },
                    {
                      value: 'Other',
                      conditionalName: `products.${index}.product_units_other`,
                      conditionalLabel: 'Enter other unit sold',
                    },
                  ]}
                  name={`products.${index}.product_units`}
                  direction="col"
                  values={values}
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const UnitWithFormik = formikWrapper(Unit);

export { UnitWithFormik as Unit };
