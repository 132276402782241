import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { InputBox, TextArea, Enum } from '../components';
import { NUMBER_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';
import { defaultProductValues } from '../initial-values';

const createQuestionTitles = (index) => {
  let products = [];
  for (let i = 0; i < index; i++) {
    products.push({
      concentration: 'Concentration of the chemical in the product',
    });
  }
  return {
    products,
  };
};

const ChemicalSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      concentration: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    })
  ),
});

const formikWrapper = withFormik({
  validationSchema: ChemicalSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Chemical',
      action: 'Completed',
    });
    delete values.matomo;
    const reportType = values.about_incident.report_type;
    let next =
      reportType === 'A theft' || reportType === 'A disappearance'
        ? 'product-review'
        : 'cost';
    if (
      values.editMode &&
      values.sections.has(`product-review.${values.productIndex}`)
    ) {
      next = 'review-answers';
    }
    values.sections.add(`chemical.${values.productIndex}`);
    formikBag.props.updateValues(values, next);
  },
});

const Chemical = ({ handleSubmit, values, setFieldValue, updateValues }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();
  const index = values.productIndex;
  const productNumber = index + 1;
  const questionTitles = createQuestionTitles(productNumber);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Chemical - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Chemical',
      action: 'Started',
    });

    const exists = values.products.filter((product) => product.id === index);
    if (exists.length === 0) {
      const newIndex = values.products.length;
      const newProduct = Object.assign({}, defaultProductValues);
      newProduct.id = newIndex;
      values.products.push(newProduct);
      values.productIndex = newIndex;
      updateValues(values);
    }
  }, [values.productIndex]);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`chemical.${index}`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <span className="govuk-caption-l">Product {productNumber}</span>
            <h1 className="govuk-label-wrapper">
              <label className="govuk-label govuk-label--l">
                About the chemical
              </label>
            </h1>
            <ErrorList questionTitles={questionTitles} />
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  question="Chemical name"
                  name={`products.${index}.chemical_name`}
                  subText="Enter the name of the most concerning explosives precursor chemical or poison in this product You might find this information on the label, in a product catalogue or on its Safety Data Sheet (SDS/MSDS)."
                  size="20"
                  hintId="chemical-name-hint"
                />
                <InputBox
                  question={questionTitles.products[index].concentration}
                  isNumber={true}
                  name={`products.${index}.concentration`}
                  size="5"
                />
                <Enum
                  question="Concentration unit"
                  enumValues={[
                    { value: 'Percent (W/W)' },
                    { value: 'Moles' },
                    { value: 'Parts Per Million' },
                  ]}
                  name={`products.${index}.product_concentration_units`}
                  direction="col"
                  key={index}
                  values={values}
                />
                <TextArea
                  question="Were there other concerning chemicals in the product?"
                  name={`products.${index}.product_other`}
                  subText="If you’re aware of any other dangerous chemicals or poisons in the product then list them below."
                  hintId="product-other-hint"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ChemicalWithFormik = formikWrapper(Chemical);

export { ChemicalWithFormik as Chemical };
