import React, {useEffect} from 'react';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { RegularReporting } from './RegularReporting';
import { OneOffReport } from './OneOffReport';
import { Contact } from './Contact';

const Login = () => {
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: 'Login - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'Login Page',
      action: 'Visited',
    });
  }, [trackPageView, trackEvent]);
  return (
    <>
      <main className="govuk-main-wrapper" id="content" role="main">
        <div className="govuk-grid-column">
          <OneOffReport />
          <RegularReporting />
          <Contact />
        </div>
      </main>
    </>
  );
};

const LoginWithAuth = withAuthorisation({
  forwardUrl: '/home',
  isLoginPage: true,
})(Login);
export { LoginWithAuth as Login };
