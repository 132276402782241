import React, { createContext } from 'react';
import { getConfig } from '../../Config';
import axios from 'axios';

const portalPublicEndpoint = getConfig().portalPublicUrl;

export const OneOffAuthContext = createContext();

const OneOffAuthProvider = (props) => {
  const local_axios = axios.create();
  const validateUser = (hash) => {
    try {
      if (hash) {
        sessionStorage.setItem('sessionHash', hash);
      } else {
        hash = sessionStorage.getItem('sessionHash');
      }
      const decodedHash = atob(hash.substring(1));
      const email = JSON.parse(decodedHash).username;
      setEmail(email);
    } catch (err) {
      Promise.reject(err);
    }

    return local_axios
      .post(`${portalPublicEndpoint}/user/validate`, {
        session: hash.substring(1),
      })
      .then(async (res) => {
        sessionStorage.setItem('token', res.data.access_token);
        sessionStorage.setItem('refreshToken', res.data.refresh_token);
        sessionStorage.setItem('idToken', res.data.id_token);
        sessionStorage.setItem('one-off-authenticated', 'true');
      });
  };

  const setEmail = (email) => {
    return sessionStorage.setItem('email', email);
  };

  const getEmail = () => {
    return sessionStorage.getItem('email');
  };

  const isOneOffAuthenticated = () => {
    return sessionStorage.getItem('one-off-authenticated') === 'true';
  };

  const clearTokens = () => {
    sessionStorage.clear();
  };

  return (
    <OneOffAuthContext.Provider
      value={{
        validateUser,
        isOneOffAuthenticated,
        clearTokens,
        getEmail,
      }}
    >
      {props.children}
    </OneOffAuthContext.Provider>
  );
};

export default OneOffAuthProvider;
