import React, { useEffect } from 'react';
import { Form, useFormikContext, withFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ORGANISATION_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';
import { Enum } from '../components';
import * as Yup from 'yup';

export const REPORTING_FOR_PROVIDER =
  'I am making a report for my business or employer';
export const EPP_LICENSE_HOLDER = 'I am an EPP license holder';
export const WITNESS_ONLY = 'None of the options apply to me';

const OrganisationSchema = Yup.object().shape({
  provider: Yup.object().shape({
    reporting_for_org: Yup.string().required(ORGANISATION_ERROR_MESSAGE),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: OrganisationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    const { matomo, editMode, sections } = values;
    matomo.trackEvent({
      category: 'About the organisation',
      action: 'Completed',
    });
    delete values.matomo;
    let nextPage = '';
    switch (values.provider.reporting_for_org) {
      case REPORTING_FOR_PROVIDER:
        nextPage = 'business-name';
        break;
      case EPP_LICENSE_HOLDER:
        nextPage = 'epp-license';
        break;
      default:
        nextPage = 'about-incident';
    }
    if (editMode) {
      nextPage = 'review-answers';
    }
    sections.add('organisation');
    formikBag.props.updateValues(values, nextPage);
  },
});

const Organisation = ({ setFieldValue, values }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  formik.values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView({
      documentTitle: 'About The Organisation - Report Suspicious Chemical Activity'
    });
    trackEvent({
      category: 'About the organisation',
      action: 'Started',
    });
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`organisation`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList/>
            <span className="govuk-caption-l">Your reporter type</span>
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <fieldset className="govuk-fieldset">
                <div className="govuk-grid-column">
                  <Enum
                      enumValues={[
                        {
                          value: REPORTING_FOR_PROVIDER,
                          name: 'for-business',
                        },
                        {
                          value: EPP_LICENSE_HOLDER,
                          name: 'epp-license-holder',
                        },
                        {
                          value: WITNESS_ONLY,
                          name: 'none',
                        },
                      ]}
                      name="provider.reporting_for_org"
                      direction="col"
                      values={values}
                      data-test="provider-reporting-type-options"
                      questionLarge={'Select the option that best describes you'}
                  />
                </div>
                <button
                    type="submit"
                    data-prevent-double-click="true"
                    className="govuk-button"
                    data-module="govuk-button"
                    data-test={`button-continue`}
                    aria-label={'Continue'}
                >
                  Continue
                </button>
              </fieldset>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const OrganisationWithFormik = formikWrapper(Organisation);

export { OrganisationWithFormik as Organisation };
